export const TINE_MCE_API_KEY =
  "v1t939qoztu76eix8vbn9uk2is7o90mv96c2hyuijhq7s29l";
export const DATA_NOT_FOUND = "-";
export const PAGINATION_LIMIT_OPTIONS = [10, 25, 50, 75, 100];
export const SORTING_DICTIONARY = {
  ascending: "asc",
  descending: "desc",
};
export const ROLE_AMOUNT_TYPE = "amount";
export const ROLE_PERCENTAGE_TYPE = "percent";
export const MARKUP_TYPES = {
  test_amount: "test_amount",
  test_percent: "test_percent",
  package_amount: "package_amount",
  package_percent: "package_percent",
};
export const MESSAGE_KEY_TYPE_OPTIONS = {
  numeric: {
    value: 1,
    label: "Code (number)",
  },
  alpha_numeric: {
    value: 2,
    label: "Password (alpha + number)",
  },
};
